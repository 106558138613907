.list-input-holder {
  display: flex;
  align-items: center;
}

.add-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  cursor: pointer;
  padding: 2px 8px;
  font-size: 15px;
  font-weight: bold;
  height: 30px;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 5px;
}

.add-button:disabled {
  cursor: default;
}

.delete-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  cursor: pointer;
  padding: 2px 8px;
  font-size: 15px;
  font-weight: bold;
  height: 30px;
  margin-left: 20px;
  margin-top: 5px;
  border-radius: 5px;
}

.added-content {
  padding: 10px;
  font-size: 18px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: left;
}

.added-content-and-delete-button {
  list-style-type: none;
  display: flex;
  width: 100%;
}

.add-content {
  display: flex;
  margin-bottom: 20px;
}

.existing-value {
  padding-left: 0;
}

.error-message {
  margin-top: 0; /* Adjust this value as needed */
}