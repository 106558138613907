.nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.nav-link {
  color: black;
  margin-left: 40px;
  text-decoration: none;
  font-weight: 400;
  margin-right: 50px;
}

.nav-bar-logo {
  width: 150px;
  margin-left: 50px;
}

.logout-nav {
  color: black;
  margin-left: 40px;
  font-weight: 400;
  margin-right: 50px;
  border: none;
  background-color: white;
  font-size: medium;
  cursor: pointer;
}
