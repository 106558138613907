.content {
  margin-bottom: 50px;
}

.page-title {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-of-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-title {
  margin-top: 0; 
}

.modal-button {
  height: 40px;
  align-content: center;
  margin-left: 50px;
  margin-top: 20px;
  background-color: white;
  border: none;
  cursor: pointer;
}

.page-content {
  width: 100%;
  margin-top: -20px;
}

.basic-advanced-download-button-bar {
  margin-top: 20px;
  align-items: center;
  width: 100%;
  display: flex;
  border: 1px solid black;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 50px;
}

.basic-chat-button {
  background-color: rgb(235, 229, 229);
  color: black;
  width: 25%;
  padding: 10px;
  border-top-left-radius: 4px;
  cursor: pointer;
  height: 50px;
  border: none;
  border-right: 1px solid black;
}

.advanced-chat-button {
  background-color: rgb(235, 229, 229);
  color: black;
  width: 25%;
  padding: 10px;
  cursor: pointer;
  height: 50px;
  border: none;
  border-right: 1px solid black;
}

.data-chat-button {
  background-color: rgb(235, 229, 229);
  color: black;
  width: 25%;
  padding: 10px;
  cursor: pointer;
  height: 50px;
  border: none;
}

.download-horizon-scanner-button {
  background-color: rgb(15, 11, 134);
  color: white;
  width: 25%;
  padding: 10px;
  cursor: pointer;
  border-top-right-radius: 4px;
  height: 50px;
  border: none;
}

.basic-chat-button.active {
  background-color: #a71e23;
  color: white;
}

.advanced-chat-button.active {
  background-color: #a71e23;
  color: white;
}

.data-chat-button.active {
  background-color: #a71e23;
  color: white;
}

.middle-excluding-button-bar {
  display: flex;
  align-items: stretch;
  height: 45vh;
}

.messages-div {
  border: 1px solid black;
  border-bottom-left-radius: 4px;
  padding: 10px;
  width: calc(75% - 12px);
  overflow-y: scroll;
}

.sent-messages-holder {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}

.message {
  padding: 8px 16px;
  width: auto;
  display: inline-block;
  margin-top: 30px;
  display: flex;
  border-radius: 12px;
  color: white;
}

.sent-messages-holder div p:first-child {
  margin-top: 0;
}

.sent-messages-holder div p:last-child {
  margin-bottom: 0;
}

.message--user {
  background-color: #a71e23;
  margin-left: auto;
  align-items: flex-end;
  position: relative;
}

.message--AI,
.message--loader {
  background-color: #2f2b88;
  margin-right: auto;
  align-items: flex-start;
  position: relative;
}

.additional-option-holder {
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.message--additional-option {
  margin-top: 15px;
  margin-left: auto;
  align-items: flex-end;
  position: relative;
  border: 2px solid #a71e23;
  color: #a71e23;
  font-weight: bold;
  cursor: pointer;
  transition:
    color 300ms ease-in-out,
    background-color 300ms ease-in-out;
  background-color: white;
}

.message--additional-option:hover {
  color: white;
  background-color: #a71e23;
}

.message--additional-option-two {
  margin-top: 15px;
  margin-left: 10px;
  align-items: flex-end;
  position: relative;
  border: 2px solid #a71e23;
  color: #a71e23;
  font-weight: bold;
  cursor: pointer;
  transition:
    color 300ms ease-in-out,
    background-color 300ms ease-in-out;
  background-color: white;
}

.message--additional-option-two:hover {
  color: white;
  background-color: #a71e23;
}

.message-loader {
  fill: white;
  stroke: white;
  width: 50px;
}

.advanced-or-basic-chat-holder {
  border: 1px solid black;
  justify-content: right;
  border-bottom-right-radius: 4px;
  width: 25%;
  overflow-y: scroll;
}

.display-basic-options-holder {
  text-align: center;
  margin-top: 50%;
  margin-bottom: 50%;
  padding: 20px;
}

.display-advanced-options-holder {
  padding: 20px;
}

.display-data-options-holder {
  text-align: center;
  margin-top: 50%;
  margin-bottom: 50%;
  padding: 20px;
}

.horizon-form {
  margin-top: 50px;
  display: flex;
  align-items: center;
}

.question-input-container {
  flex-grow: 1;
}

.button {
  margin-left: 8px;
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  min-height: 30px;
  transition:
    background-color 300ms ease-in-out,
    border 300ms ease-in-out;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 18px;
  margin-bottom: 9px;
  font-weight: bold;
}

.button:hover {
  background-color: white;
  color: #a71e23;
}

.button:disabled {
  pointer-events: none;
  opacity: 0.6;
}

.start-again {
  background: none;
  border: none;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  margin-left: 20px;
}
