.signup-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.signup-content {
    max-width: 600px;
    margin-top: 15vh;
}
