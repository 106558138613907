.upload-horizon-div {
  margin-top: 50px;
}

.upload-horizon-button {
  margin-top: 20px;
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;
  font-size: 10px;
  font-weight: bold;
  height: 30px;
}

.download-stats-button {
  margin-top: 50px;
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;
  font-size: 10px;
  font-weight: bold;
  height: 30px;
}
