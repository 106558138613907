@font-face {
  font-family: "SantralRegular";
  src: url("/public/Santral-Regular.woff") format("woff");
}

body {
  margin: 0;
  font-family: "SantralRegular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button,
a,
span,
li,
input {
  font-family: "SantralRegular", Helvetica, Arial, sans-serif;
}

.content {
  padding: 16px;
  max-width: 1000px;
  margin: auto;
}

a {
  color: white;
}

button:disabled {
  opacity: 0.7;
}

svg {
  color: black;
  width: 18px;
}
