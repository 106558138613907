.file-upload-holder {
  margin-top: 0px;
  margin-bottom: 50px;
  margin-left: 0px;
  border-width: 2px;
  border-color: brown;
}

.file-upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: none;
  color: #a71e23;
  border: 2px solid #a71e23;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

/* Optional: Hover effect */
.file-upload-button:hover {
  background-color: #a71e23;
  color: white;
}
