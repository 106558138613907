.input__container {
  width: 100%;
}

.input__container--checkbox {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
}

.input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: 1px solid black;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: text;
  margin-bottom: 30px;
}

.input[type="checkbox"] {
  width: auto;
  margin-right: 8px;
}

textarea {
  min-height: 100px;
}

.input__label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
}

.input__help-text {
  display: block;
  margin-top: 2px;
  font-size: 12px;
  color: var(--secondary-text);
}

.input--readonly {
  border-color: transparent;
  margin: 0;
}

.input__container--invalid {
  color: #a71e23;
}

.input__container--invalid input {
  color: #a71e23;
}
