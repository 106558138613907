.login-content {
  max-width: 600px;
  margin-top: 15vh;
}

.login-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-logo {
  width: 200px;
}

.form-submitter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.login-loader {
  width: 30px;
  margin-top: 5px;
  fill: white;
  stroke: white;
}

.create-account-link {
  color: black;
}
