.research-page-content {
  margin-top: 50px;
  padding: 16px;
  max-width: 1000px;
  margin: auto;
}

.title-and-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-button {
  height: 40px;
  align-content: center;
  margin-left: 50px;
  margin-top: 20px;
  background-color: white;
  border: none;
}

.scanforme-uploadurl-text-bar {
  /* margin-top: 20px;  */
  margin-top: 0px; 
  align-items: center;
  width: 100%;
  display: flex;
  border: 1px solid black;
  border-radius: 4px;
}

.scan-me-button {
  background-color: rgb(235, 229, 229);
  border-width: 1px;
  color: black;
  width: 50%;
  padding: 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
  height: 50px;
}

.scan-me-button.active {
  background-color: #a71e23;
  color: white;
}

.url-button {
  background-color: rgb(235, 229, 229);
  border-width: 1px;
  color: black;
  width: 25%;
  padding: 10px;
  cursor: pointer;
  height: 50px;
}

.url-button.active {
  background-color: #a71e23;
  color: white;
}

.upload-button {
  background-color: rgb(235, 229, 229);
  border-width: 1px;
  color: black;
  width: 25%;
  padding: 10px;
  cursor: pointer;
  height: 50px;
}

.upload-button.active {
  background-color: #a71e23;
  color: white;
}

.text-button {
  background-color: rgb(235, 229, 229);
  border-width: 1px;
  color: black;
  width: 50%;
  padding: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  height: 50px;
}

.text-button.active {
  background-color: #a71e23;
  color: white;
}

.form-submitting-scan-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  min-height: 30px;
  transition:
    background-color 300ms ease-in-out,
    border 300ms ease-in-out;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 18px;
  margin-bottom: 9px;
  font-weight: bold;
}

.scan-results-email-options {
  margin-top: 50px;
}

.scan-results-email-form {
  display: flex;
  align-items: center;
  height: 10px;
}

.build-ren-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: bold;
}

.building-response-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.start-again-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px;
  font-size: 10px;
  font-weight: bold;
  height: 30px;
  margin-left: 20px;
}

.email-input-and-build-button {
  margin-top: 50px;
  width: 95%;
}
