.terms-form-whole-container {
  margin-top: 50px;
  width: 95%;
}

.scan-results-and-select-scroller {
  display: flex;
  align-items: center;
}

.scan-results-previous-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: bold;
  margin-right: 20px;
}

.scan-results-content-holder {
  font-size: small;
  width: 80%;
  margin-top: 50px;
}

.scan-results-next-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 20px;
}

.select-paragraph-button {
  border: 2px solid #a71e23;
  background-color: #a71e23;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 105px;
  margin-top: 20px;
}

.terms-and-sites-input-container {
  display: flex;
}

.term-input-holder {
  width: 50%;
}

.whitelist-input-holder {
  margin-left: 30px;
  width: 50%;
}
